module controllers {
    export module applicationcore {
        interface IMenuSelectScope extends ng.IScope {
            data: Array<interfaces.applicationcore.IMenu>;
            loadData(): ng.IPromise<Array<interfaces.applicationcore.IMenu>>
        }

        export class menuSelectCtrl {


            static $inject = ['$scope', '$rootScope', '$state', "bsLoadingOverlayService"];

            alphabet: Array<string>;
            selectedLetter: string;

            filterData: Array<interfaces.applicationcore.IMenu> = this.$scope.data;
            searchText: string = '';
            constructor(private $scope: IMenuSelectScope, private $rootScope: interfaces.applicationcore.IRootScope, private $state: ng.ui.IStateService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService) {
                var str = "abcdefghijklmnopqrstuvwxyz";
                this.alphabet = str.toUpperCase().split("");
                this.selectedLetter = '';



                bsLoadingOverlayService.wrap({
                    referenceId: 'menu.items'
                }, () => {
                    return $scope.loadData().then((result) => {
                        $scope.data = result;
                        this.searchChanged();
                    }, () => {

                    });
                });


            }

            public selectLetter(aplhaLetter: string) {
                this.selectedLetter = aplhaLetter;
            }

            public hasLetter(letter: string) {
                return _.findIndex(this.filterData, (o) => {
                    return o.name.indexOf(letter) === 0;
                }) > -1;
            }

            public openIFrame(menuItem: interfaces.applicationcore.IMenu) {
                this.$state.go('auth.MasterData.IFrame', { name: menuItem.name, url: menuItem.url });
            };

            public searchChanged() {
                this.searchText = this.searchText || '';

                this.filterData = _.filter(this.$scope.data, (n: interfaces.applicationcore.IMenu) => {
                    return (n.name.toLocaleLowerCase().indexOf(this.searchText.toLocaleLowerCase()) >= 0 || this.searchText.length === 0);
                });
            }
        };

        angular.module("app").controller("menuSelectCtrl", controllers.applicationcore.menuSelectCtrl);
    }
}